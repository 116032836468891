import React from "react"
import styled from "styled-components"
import Container from "../components/Container"
import Layout from "../layouts/default"
import Page from "../components/Page"
import SEO from "../components/seo"
import usePageHeader from "../hooks/usePageHeader"
import { H1, H2, P, UL } from "../styles"

const Content = styled(Container)`
  padding-top: 4rem;
  padding-bottom: 8rem;

  ${H2} {
    margin: 8rem 0 4rem 0;
  }

  ${UL} {
    margin: 4rem 2rem;
  }

  ${P},li {
    color: ${({ theme }) => theme.colors.greyLight};
  }
`

const Subsection = styled.h3`
  color: ${({ theme }) => theme.colors.offWhite};
`

const TermsOfServicePage = () => {
  const [pageHeaderRef, pageHeaderHeight] = usePageHeader()
  return (
    <Layout>
      <SEO title="Phi | Terms of Service" />
      <Page as="main" pageHeaderHeight={pageHeaderHeight}>
        <Page.Header as="header" columnWidth={800} ref={pageHeaderRef}>
          <H1 as={H2}>PHENOMETRY TERMS OF SERVICE</H1>
        </Page.Header>
        <Content columnWidth={800} as="article">
          <P>
            The following terms and conditions (“Terms of Service”) govern your
            use of the Phenometry website, the Phenometry Phi 3D surface
            modeling software (“Phi”) and all related data, information,
            documentation and services provided by Phenometry directly or
            through Onshape or other Phenometry partners (“Partners”) and their
            respective software platforms (collectively the “Phenometry
            Services”). If you do not agree with these Terms of Service or any
            part of them, then you must not use the Phenometry Services in any
            way. By using the Phenometry Services you are agreeing to be legally
            bound by the following Terms of Service, so please read the
            following carefully before agreeing to use the Phenometry Services.
          </P>
          <P>
            Phenometry P.C. (“Phenometry”), a company registered in Greece
            (registration number 150871803000), reserves the right to amend,
            change or update the Terms of Service governing use of the
            Phenometry Services without notice. Any enhancements, modifications
            or new features and functionalities of the Phenometry Services shall
            also be governed by these Terms of Service. Your continued use of
            the Phenometry Services shall constitute your acceptance of such
            changes and of the Terms of Service. The latest copy of the Terms of
            Service can be found at the Phenometry web site.
          </P>
          <P>
            The Phenometry Services are provided “AS IS”, without any
            representations or warranties of any kind. You agree to use the
            Phenometry Services at your own risk.
          </P>
          <H2>I. GENERAL DISCLAIMER</H2>
          <P>
            Phenometry Phi is a 3D design software system with emphasis on
            free-form surface design.
          </P>
          <P>
            The Phenometry Services are provided “AS IS” and without any
            warranty, express or implied. Phenometry makes no representation or
            warranty as to the reliability, accuracy, timeliness, usefulness or
            completeness of the Phenometry Services and we assume no liability
            or responsibility for any errors or omissions in the content
            provided by the Phenometry Services.
          </P>
          <H2>II. ACCEPTANCE OF TERMS</H2>
          <P>
            You hereby agree that by registering with Phenometry, or by using
            the Phenometry Services you are concluding a legally binding
            agreement with Phenometry. This agreement is governed by the terms
            and conditions set forth herewithin, including our Privacy Policy
            and our Pricing Policy (when made available in the Phenometry web
            site or through Partners) which are hereby incorporated by reference
            (collectively referred to as the “Agreement”). Please read them
            carefully.
          </P>
          <P>
            By registering with Phenometry or by accessing the Phenometry
            Services through the websites, app stores or other software
            platforms of any Partner or by using the Phenometry Services in any
            way you acknowledge and accept that you have read and understood the
            terms of this Agreement and that you agree to be bound by all of its
            provisions.
          </P>
          <P>
            If you don’t accept or agree with the terms of the Agreement in
            their entirety, you must not register with Phenometry, and you must exit and stop using the
            Phenometry Services in any way.
          </P>
          <P>
            Phenometry reserves the right to make changes to the Phenometry
            Services and to this Agreement (including all of its components such
            as Privacy Policy, Pricing Policy etc) at any time WITHOUT NOTICE TO
            YOU. Any enhancements, modifications or new features and
            functionalities of the Phenometry Services shall also be governed by
            the Agreement. Your continued use of the Phenometry Services shall
            constitute your complete acceptance of such changes and of the
            Agreement as posted on the Phenometry website at the time of your
            access or use. You agree that it is your responsibility to review
            the terms of this Agreement each time you access the Phenometry
            Services so that you are aware of any modifications made to this
            Agreement.
          </P>
          <H2>III. MEMBER ACCOUNT, PASSWORD, AND SECURITY</H2>
          <P>
            Using the Phenometry Services may require you to have an account
            (the “Account”). Such an account may be created for you by
            Phenometry or you may be required to open one. In order to open an
            account, you should complete the registration process by providing
            us with current, complete and accurate information as prompted by
            the applicable registration form. In the process, you will also
            choose a password and a username / email address. Alternatively, you
            may need to have an Onshape account or an account with another
            Partner. We may use the email-address you log in to Onshape with
            only to send you emails related to your subscription to Phi. If you
            are a student or an educator applying for an academic license, an
            additional registration will be required. Phenometry does not sell
            individual names or any nonpublic personal information to third
            parties.
          </P>
          <P>
            The username / email address and any password for each Account (the
            “Account ID”) are personal in nature. If you are an individual, your
            Account ID may be used only by you alone. If you are acting on
            behalf of an entity or organization, each Account ID may be used
            only by a single designated individual within your organization.
          </P>
          <P>
            You are entirely responsible for maintaining the confidentiality of
            your Account ID and for any and all activities that occur under your
            Account. You agree to notify Phenometry immediately of any
            unauthorized use of your Account or any other breach of security.
            Phenometry will not be liable for any loss or damage that you may
            incur as a result of someone else using your password or Account,
            either with or without your knowledge. You also agree that you could
            be held liable for losses incurred by Phenometry or another party
            due to someone else using your Account or password.
          </P>
          <H2>IV. GRANT OF RIGHT AND LICENSE TO USE THE PHENOMETRY SERVICES</H2>
          <P>
            Subject to your compliance with, and during the term of this
            Agreement, Phenometry grants you a non-exclusive, personal,
            non-transferable, non-sublicensable paid up limited license
            (“License”) to use the Phenometry Services only for your own
            internal purposes in accordance with this Agreement.
          </P>
          <P>
            Except as expressly provided in this Agreement, no other right is
            hereby granted to you. In no event shall you modify, copy,
            distribute, transmit, display in public, perform, reproduce,
            duplicate, publish, license, create derivative works from, reverse
            engineer, transfer, rent, lease or sell in whole or in part any
            information, software, data or services obtained from accessing or
            using the Phenometry Services, or attempt to do so.
          </P>
          <P>
            You shall not be entitled to assign, sub-license, rent, lease or
            otherwise transfer the License in whole or in part.
          </P>
          <P>
            Trial and Educational/Academic Licenses:
            <UL>
              <li>
                Trial Licenses are intended for evaluation purposes, and must be used exclusively for non-commercial purposes during the evaluation period.
              </li>
              <li>
                Educational/Academic Licenses are intended for use in educational and academic contexts, including but not limited to classroom instruction, personal learning projects, school clubs/organizations, and academic research. They must not be used for commercial or other organizational use.
              </li>
            </UL>
            Trial and Educational/Academic Licenses are otherwise subject to the terms of this Agreement.
          </P>

          <H2>V. INTELLECTUAL PROPERTY &amp; OWNERSHIP</H2>
          <Subsection>A) Phenometry Content</Subsection>
          <P>
            The Phenometry Services including, without limitation, software,
            website structure and organization, algorithms, source code, binary
            executables, databases, and all text, files, images, graphics,
            illustrations, information, documentation, data, audio, video,
            photographs and other content (collectively, "Phenometry Content")
            contained in or made available through the Phenometry Services and
            the structure, selection, coordination, “look and feel” and
            arrangement of the Phenometry Content, and any other proprietary
            data or technology used by or on behalf of Phenometry to provide the
            Services are the property of Phenometry and/or Phenometry's
            licensors, and are protected by applicable intellectual property
            laws.
          </P>
          <P>
            Phenometry, the aforementioned entities and Phenometry's licensors
            retain all right, title and interest, including, without limitation,
            all intellectual property rights, in and to the Phenometry Services
            and Phenometry Content and any additions, improvements, updates, and
            modifications thereto.
          </P>
          <P>
            The Phenometry and Phi names, logos, and the product and service
            names associated with the Phenometry Services are trademarks of
            Phenometry and no right or license is granted to you to use them
            separate from your right to use the Phenometry Services.
          </P>
          <P>
            Please be aware that we actively and aggressively enforce our
            intellectual property rights to the fullest extent of the law.
          </P>
          <Subsection>B) Your Content</Subsection>
          <P>
            You maintain ownership of and responsibility for the following: (1)
            files, designs, models, data sets, images, or similar material or
            information that you transmit to, or otherwise use in connection
            with the Phenometry Services, and (2) any specific output generated
            by the Phenometry Services, if any, based on any of the foregoing
            (collectively, “Your Content”). By making Your Content available to
            Phenometry, you acknowledge and agree that (1) you have sole
            responsibility for the accuracy, quality, integrity, legality,
            reliability, and appropriateness of Your Content, (2) you will
            evaluate and bear all risks associated with Your Content, and (3)
            under no circumstances will Phenometry and/or its affiliates, agents
            and licensors and each of their respective officers, directors, and
            employees be liable in any way for Your Content as you transmit or
            otherwise use it, including but not limited to any errors or
            omissions. In your use of the Phenometry Services, you agree to
            respect others’ intellectual property and other rights.
          </P>
          <P>
            If you are using the Phenometry Services under an
            educational/academic license, you further acknowledge and agree that
            as a condition of this educational license Your Content can be
            automatically shared with Phenometry, through a Phenometry account,
            a Phenometry Onshape account or other Phenometry Partner account
            depending on how you use the Phenometry Services. Phenometry will
            have view-only access to Your Content and may use it for the purpose
            of improving Phi and the Phenometry Services, and providing user
            support. Phenometry will not publicize or share Your Content with
            third parties without your written permission.
          </P>
          <H2>VI. NO UNLAWFUL OR PROHIBITED USE OF THE PHENOMETRY SERVICES</H2>
          <P>
            As a condition of your use of the Phenometry Services, you shall not
            use the Phenometry Services or License for any purpose or in any way
            that is unlawful or prohibited by the terms and conditions of this
            Agreement.
          </P>
          <P>
            Without limitation, you shall not, and shall not permit any other
            third party to:
          </P>
          <UL>
            <li>
              use or otherwise exploit the Phenometry Services in ways expressly
              forbidden in Section IV (Grant of Right and License),
            </li>
            <li>
              use any “deep-link”, “page-scrape”, “robot”, “spider”, web
              browsing automation tool or other automatic device, program,
              algorithm or methodology (including, without limitation data
              mining, data harvesting and data extraction), or any similar or
              equivalent manual process, to access, acquire, copy or monitor any
              portion of the Phenometry Services,
            </li>
            <li>
              reverse engineer, decompile, disassemble, or otherwise attempt to
              derive the source code, algorithm, databases or structure of any
              portion of the Phenometry Services,
            </li>
            <li>
              in any way reproduce or circumvent, or attempt to circumvent, any
              electronic protection measures in place to regulate or control
              access to the Phenometry Services,
            </li>
            <li>
              interfere in any manner with the operation of the Phenometry
              Services or attempt to gain unauthorized access to the Phenometry
              Services,
            </li>
            <li>
              develop, distribute or sell any software or other functionality
              capable of launching, being launched from, or otherwise integrated
              with the Phenometry Services,
            </li>
            <li>
              remove, alter or obscure any copyright notice or any other
              proprietary notice that appears in the Phenometry Services,
            </li>
            <li>
              use the Phenometry Services in any way that may cause damage to,
              or impair accessibility to or availability of the Phenometry
              Services,
            </li>
            <li>
              use the Phenometry Services in any way connected to fraudulent,
              illegal, unlawful or harmful activities or aims.
            </li>
          </UL>
          <P>
            Phenometry, either upon breach of any of the terms of this Agreement
            by you or in case of temporary or free evaluation Accounts that
            Phenometry may have provided you with, reserves the right to
            deactivate your Account ID and to bar any prohibited activity, with
            or without notice to you, at its sole discretion.
          </P>
          <H2>VII. TERMINATION</H2>
          <P>
            This Agreement will begin as soon as you register or start using the Phenometry
            Services in any way and will continue until terminated.
          </P>
          <P>
            Phenometry may restrict, suspend or terminate this Agreement
            immediately upon breach of any of its terms or upon any other misuse
            or abuse of the Phenometry Services that Phenometry deems contrary
            to its purpose, at Phenometry’s sole discretion and at any time,
            with or without notice to you.
          </P>
          <P>
            Upon termination of this Agreement, all rights granted to you under
            this Agreement will cease, any Account you have established will be
            closed and you will lose access to the Phenometry Services. Sections
            V, VI, IX, X, XI, XIV, XV, XVI and all disclaimer and liability
            limitation sections and clauses shall survive any such termination
            or expiration.
          </P>
          <H2>VIII. PAYMENT TERMS</H2>
          <P>
            If you purchase any services that we offer for a fee, either on
            Pay-Per-Use or subscription basis, you agree to pay the applicable
            fees as they become due plus all related taxes, and to reimburse us
            for all collection costs and interest for any overdue amounts,
            subject to Phenometry’s Pricing Policy and the pricing policy of
            Onshape or other Partner through which you procured access to the
            Phenometry Services.
          </P>
          <P>
            Payments made by you through the Phenometry Services cannot be
            exchanged and are non-refundable. All information that you provide
            in connection with your billing account and a purchase or other
            transaction through the Phenometry Services must be accurate,
            complete and current.
          </P>
          <P>
            You hereby agree to authorize Phenometry or Phenometry’s payment service provider or Onshape or other
            Partners you may be accessing the Phenometry Services through, to
            charge the credit card, debit card, Paypal account or other payment
            method provided in connection with any transaction through the
            Phenometry Services.
          </P>
          <H2>IX. USER REPRESENTATIONS</H2>
          <P>
            To be eligible to use the Phenometry Services, you must meet the
            following criteria. You hereby represent and warrant that:
          </P>
          <UL>
            <li>
              you are not currently restricted from the Phenometry Services, and
              are not otherwise prohibited from having a Phenometry account,
            </li>
            <li>
              all information you provide to Phenometry in connection with this
              Agreement is accurate, complete and current,
            </li>
            <li>
              you are not a competitor or an employee or affiliate of, or
              associated with, a competitor of Phenometry and you are not using
              the Phenometry Services for any reason or purpose that is or may
              be in competition with Phenometry (unless you have a written
              permission or invitation by Phenometry to use the Phenometry
              Services),
            </li>
            <li>
              you have full power and authority to enter into this Agreement and
              doing so will not violate any other agreement to which you are a
              party,
            </li>
            <li>
              you will not violate any rights of Phenometry, including
              intellectual property rights such as copyright or trademark rights
              and
            </li>
            <li>
              you agree to provide at your cost all equipment, software, and
              internet access necessary to use the Phenometry Services.
            </li>
          </UL>
          <H2>X. NO WARRANTIES</H2>
          <P>
            PHENOMETRY PROVIDES THE PHENOMETRY SERVICES AND ALL PHENOMETRY
            CONTENT ON AN “AS IS” AND “AS AVAILABLE” BASIS. PHENOMETRY DOES NOT
            PROVIDE ANY WARRANTIES OR REPRESENTATIONS OF ANY KIND WHATSOEVER
            REGARDING THE PHENOMETRY SERVICES.
          </P>
          <P>
            PHENOMETRY EXPRESSLY DISCLAIMS AND EXCLUDES ANY AND ALL WARRANTIES,
            WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
            LIMITATION, ALL WARRANTIES OF TITLE, QUALITY, PERFORMANCE,
            NONINFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE. NOR ARE THERE ANY WARRANTIES CREATED BY A COURSE OF
            DEALING, COURSE OF PERFORMANCE OR TRADE USAGE.
          </P>
          <P>
            Without prejudice to the generality of the foregoing paragraphs in
            this Section, Phenometry does not warrant:
          </P>
          <UL>
            <li>
              the accuracy, completeness, effectiveness, performance or
              availability of the Phenometry Services. You will be solely
              responsible and liable for your use of and reliance on the
              Phenometry Services and for all decisions or actions resulting
              from your use of the Phenometry Services,
            </li>
            <li>
              that your use of the Phenometry Services will be error-free or
              uninterrupted, timely or secure,
            </li>
            <li>
              that the results obtained from the use of the Phenometry Services
              or any data or material contained in the Phenometry Services will
              be accurate, true, reliable, non-misleading or reproducible, or
              that they will meet your requirements and
            </li>
            <li>
              that data you have entered into the Phenometry Services will not
              be lost, corrupted or damaged.
            </li>
          </UL>
          <H2>XI. LIMITATION OF LIABILITY</H2>
          <P>
            IN NO EVENT SHALL PHENOMETRY BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
            CONSEQUENTIAL, SPECIAL OR PUNITIVE LOSSES OR DAMAGES OF ANY KIND, OR
            UNDER ANY OTHER LEGAL THEORY, ARISING FROM OR RELATING TO THIS
            AGREEMENT, INCLUDING, WITHOUT LIMITATION, COSTS, DAMAGES OR
            LIABILITIES RELATING TO YOUR ACCESS TO OR USE OF (OR YOUR FAILURE TO
            GAIN ACCESS TO OR USE OF) THE PHENOMETRY SERVICES, OF ANY KIND
            WHATSOEVER, INCLUDING, WITHOUT LIMITATION, LOSS OF USE, REVENUES,
            PROFITS, BUSINESS, DATA, OPPORTUNITY, REPUTATION OR REGISTRATION,
            COSTS OF COVER OR CAPITAL, DOWN TIME RELATED COSTS, AND ANY SIMILAR
            COSTS OR DAMAGES, REGARDLESS OF THE FORM OF ACTION OR THEORY OF
            RECOVERY (WHETHER CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE),
            STRICT LIABILITY OR OTHERWISE) AND EVEN IF PHENOMETRY HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </P>
          <P>
            Without prejudice to the generality of the foregoing paragraphs
            about liability limitation, Phenometry further clarifies and states
            and you understand and accept that:
          </P>
          <UL>
            <li>
              your access to the Phenometry Services is at your own risk and
              that you are solely responsible for any liability or damage you
              incur through access to the Phenometry Services.
            </li>
            <li>
              Though Phenometry endeavors to use server facilities of the
              highest caliber, the current state of technology worldwide does
              not allow for error-free access to the Phenometry Services and
              interruptions, crashes, and downtime may occur from time to time.
              As mentioned above, Phenometry does not warrant that the services
              it provides will function without interruption or errors in
              functioning. Phenometry disclaims any and all liability for
              damages caused by any such interruption or errors in functioning.
              Furthermore, Phenometry disclaims any and all liability for any
              malfunctioning, impossibility of access, or poor use conditions of
              the Phenometry Services due to inappropriate equipment,
              disturbances related to internet service providers, to the
              saturation of the internet network, or for any other reason.
            </li>
            <li>
              Phenometry does not have any obligation to verify the identity of
              the persons subscribing to its services, nor does it have any
              obligation to monitor the use of its services by other users of
              the Phenometry Services. Therefore, Phenometry disclaims any and
              all liability for identity theft or any other misuse of your
              identity or information.
            </li>
          </UL>
          <P>
            Phenometry’s aggregate liability arising from or relating to this
            Agreement shall in no event ever exceed the amount of (i) your
            remaining subscription days cost or (ii) 200 USD, whichever is
            higher.
          </P>
          <H2>XII. LINKS TO THIRD PARTY WEB SITES</H2>
          <P>
            The Phenometry Services may contain links to other websites.
            Phenometry is not responsible for the privacy practices or the
            content of these websites. Your access of any off-site content
            linked to the Phenometry Services is at your own risk. Please visit
            the privacy policies of these third party sites in order to
            understand their privacy and information collection practices.
          </P>
          <H2>XIII. LANGUAGE AND COMMUNICATION</H2>
          <P>
            You acknowledge and understand that the Terms and Conditions of this
            Agreement (and all of its components such as Pricing Policy, Privacy
            Policy etc) are provided in the English language and you agree that
            you completely understand all said such Terms and Conditions. The
            Agreement and all documentation and communications required
            hereunder shall be in the English language only.
          </P>
          <H2>XIV. APPLICABLE LAWS AND JURISDICTION</H2>
          <P>
            The present Agreement is governed by and interpreted in accordance
            with the applicable laws of Greece and in case of a dispute arising
            from or relating to this Agreement, the contracting parties shall
            submit to the jurisdiction of the courts of Athens, Greece.
          </P>
          <H2>XV. SEVERABILITY</H2>
          <P>
            The provisions of this Agreement are severable, and in the event any
            provision hereof is determined to be invalid or unenforceable, it
            shall be read down to the extent necessary so that it is legal and
            enforceable. Such invalidity or unenforceability shall not in any
            way affect the validity or enforceability of the remaining
            provisions hereof, which shall remain in full force and effect.
          </P>
          <H2>XVI. ENTIRE AGREEMENT</H2>
          <P>
            This Agreement constitutes the entire Agreement that shall apply in
            connection with the subject matter hereof, and there are no other
            agreements or understanding, written or oral, except as provided
            herein.
          </P>
        </Content>
      </Page>
    </Layout>
  )
}

export default TermsOfServicePage
